@import url('https://fonts.googleapis.com/css2?family=Cabin&display=swap');

html {
  height: 100%;
  width: 100%; 
}

body {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
}

.App {
  text-align: center;
  height: 100%;
}

.page-body {
  max-width: 1440px;
  margin: 0 auto;
  padding-top: 20px;
  background-color: #F8F8FA;
  padding-left: 40px;
  padding-right: 40px;
}

nav {
  display: flex;
  justify-content: s1pace-around;
  align-items: center;
  min-height: 10vh;
  background: lightblue;
  color: black;
}

.nav-links {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

.nav-link-label {
  color: white; 
}

.nav-menu-links {
  margin: 0 auto; 
}


.page-widget-container {
  display: flex;
  justify-content: right;
  margin: auto 0; 
  margin-left: auto; 
}

.page-sub-title {
  margin-top: 4px; 
}

h1 {
  margin: 0; 
}

h2 {
  margin: 0; 
}

h3 {
  margin: 0;
}

h4 {
  margin: 0; 
}

.site-link {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.dark-shadow {
  box-shadow: 2px 2px 4px #666666;
}

.light-shadow {
  box-shadow: 2px 2px 4px #bbbbbb;
}

.non-border-button {
  font-size: 100%;
  font-family: inherit;
  border: 0;
  padding: 0;
  background-color: inherit;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.non-border-button::-moz-focus-inner {
  border: 0; 
}

.non-border-button:focus {
  border: 0; 
}

.spendlocal-button-container {
  border-radius: 4px;
  margin-left: 8px;
  overflow: hidden;
}

.spendlocal-cancel-button {
  padding: 8px !important;
  background-color: #F8ABB5 !important;
  color: black !important;
  font-family: 'Cabin' !important;
  font-size: 20px !important;
  transition: all .3s;
}

.spendlocal-cancel-button:hover {
  background-color: #F48B7F !important;
}



.spendlocal-accept-button {
  background-color: #D7E7D4 !important;
  padding: 8px !important;
  color: #32A81D !important;
  font-family: 'Cabin' !important;
  font-size: 20px !important;
  transition: all .3s;
}
.spendlocal-accept-button:hover {
  background-color: #B3DCAB !important;
}


@media only screen and (max-width: 600px) {
  .page-body {
    padding: 16px;
  }
}