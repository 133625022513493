:root {
    /* Colors */
    --sl-dark-green: #38862A;
    --sl-green: #68BB59;
    --sl-gray: #E5E5E5;
    --sl-yellow: #F2C94D;
    --sl-purple: #A234C4;
    --sl-light-purple: #BA3CE0;
    --sl-text: #4C4C4C;
    --sl-dark-text: #444444c; 

    /* Padding */
    --sl-padding-small: 8px;
    --sl-padding-medium: 16px;
    --sl-padding-large: 32px;
}
