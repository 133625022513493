.user-profile-header {
    display: flex;
    padding-top: 30px;
    flex-wrap: wrap;
}

.user-profile-image {
    width: 128px;
    height: 128px;
    border-radius: 64px;
    min-width: 128px;
}

.user-profile-manage-button {
    width: 100%;
    height: 100%;
    transition: all .3s; 
    display: flex;
    align-items: center;
}

.user-profile-manage-button:hover {
    background-color: #38862A;
}

.user-profile-manage-button-container {
    max-width: 185px;
    height: 48px;
    background-color: #68BB59;
    border-radius: 8px;
    overflow: hidden;
    color: white;
    font-family: 'Cabin';
    font-size: 22px;
    margin-top: 16px;
}

.user-profile-name {
    text-align: left;
    font-family: 'Cabin';
    font-size: 22px;
}

.user-profile-info {
    width: 100%;
    padding-left: 32px;
}

.user-profile-info-body {
    float: right;
    position: relative;
}

.user-profile-edit-icon {
    margin-right: 4px;
}