.local-business-support-page {
    margin-left: auto;
    margin-right: auto;
}

.local-business-support-items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.local-business-support-cause-tag {
    max-width: 400px;
    margin: 16px;
}

@media only screen and (max-width: 600px) {
    .local-business-support-cause-tag {
      margin: 0px;
    }
  }