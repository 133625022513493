hr {
    margin: 8px !important;
}

.cause-tag-container {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #E5E5E5;

    background-color: #68BB59;
    color: white;
    border-bottom: 4px solid #F2C94D;

    /* background-color: inherit;
    border-bottom: 4px solid #38862A; */
    margin-bottom: 32px;
    display: flex;
    flex-flow: column;
    transition: all .3s;
    cursor: pointer;
    text-decoration: none;
    padding: 0px;
    overflow: hidden;
}

.cause-tag-container:hover {
    box-shadow: 4px 4px 8px #bbbbbb;
    margin-left: -2px;
    margin-top: -2px;

}

.cause-tag.solo-tag {
    background-color: #38862A;
    color: white;
    border-bottom: 4px solid #F2C94D;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.4),
      0px 2px 5px 0px rgba(50, 50, 93, 0.4), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.3);
}

.cause-tag-title {
    margin-top: 40px;
    font-size: 24px;
    font-family: 'Cabin';
    margin-bottom: 8px;
    text-decoration: none;
    font-weight: bold;
}

.cause-tag {
    position: relative;
}

.cause-tag-inner-container {
    padding: 12px;
}

.cause-tag-accent-title {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #38862A;
    height: 40px;
    font-size: 20px;
    color: white;
    justify-content: center;
    font-weight: bold;
}

.cause-tag-promise-title {
    font-size: 20px;
    font-weight: bold;
}

.cause-tag-promise-container {
    border: 1px solid #EAEAEA;
    border-radius: 8px;
    padding: 8px;
    font-family: 'Cabin';
    margin-bottom: 12px;
    background-color: white;
    color: black;
    transition: all .3s;
}   


.cause-tag-promise {
    font-size: 16px; 
}

.cause-tag-promise-body {
    font-size: 24px;
}

.cause-tag-benefit-container {
    margin-top: 16px;
}

.cause-tag-benefit {
    font-size: 16px;
    margin-bottom: 8px;
}

.cause-tag-featured-option {
    font-size: 20px;
    border-radius: 8px;
    height: 40px;
    padding: 8px;
    width: auto;
    background-color: #a234c4;
    font-family: 'Cabin';
    align-items: center;
    display: flex;
    width: 100px;
    justify-content: center;
    position: absolute;
    left: 8px;
    top: 8px;
    z-index: 2;
    border: 1px solid black;
    color: white;
    font-size: bold;
}

.cause-tag-feature-button {
    padding: 8px;
    background-color: #a234c4;
    border-radius: 8px;
    font-family: 'Cabin';
    margin: 8px;
    transition: .3s all;
}

.cause-tag-feature-button:hover {
    background-color: #ba3ce0;
    color: black;
}

.cause-tag-call-to-action {
    height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-top: 4px solid #E5E5E5;
    margin-top: 8px;
    font-size: 24px;
    font-family: 'Cabin';
    background-color: #38862A;
    border-radius: 4px;
    color: white;
    transition: all .3s;
}

.cause-tag-call-to-action:hover {
    background-color: #F2C94D;
    color: black;
}


.cause-tag-terms {
    text-align: left;
    font-size: 12px;
    margin-top: 8px;
}

