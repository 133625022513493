.local-business-goal {
    width: 100%;
    border-radius: 4px;
    height: 8px;
    background-color: #C3DBBF;
}

.local-business-goal-achieved {
    height: 100%;
    background-color: #38862A;
    border-radius: 4px;
}

.local-business-goal-text {
    text-align: left;
    margin-bottom: 40px;
}

.local-business-goal-text-achieved {
    color: #32A81D;
    font-size: 32px;
    font-family: 'Cabin';
}

.local-business-goal-text-goal {
    font-size: 18px;
    font-family: 'Cabin';   
    color: #4C4C4C;
    margin-left: 8px;
}