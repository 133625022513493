
.page-header {
  display: flex;
  justify-content: left;
  padding-top: 20px;
}

.page-main-title {
  text-align: left;
  font-family: 'Cabin';
}

.page-sub-title {
  text-align: left;
  font-size: 20px;
  font-family: 'Cabin';
  margin-bottom: 4px;
}