.spendlocal-footer {
    width: 100%;
    height: 70px;
    color: green;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spendlocal-footer-link {
    padding: 16px;
    text-decoration: none;
}