.dollar-choice-button {
    border: none; 
    font-size: 24px;
    background-color: inherit;
    border: 1px solid gray;
    border-radius: 4px;
    margin: 4px;
    box-sizing: border-box;
    transition: all .3s;
    font-family: 'Cabin';
} 

.dollar-choice-button.selected {
    border:4px solid #38862A;
}

/* .dollar-choice-button.minimumForBenefit {
    border-bottom: 4px solid #F2C94D;
} */