.not-exist-body {
    width: 100%;
    font-family: 'Cabin';
    font-size: 36px;
}

.not-exist-cta {
    margin-top: 24px;
    font-size: 24px;
}

.not-exist-cta-button {
    width: 150px;
    margin: 0 auto;
    font-size: 24px;
    background-color: #38862A;
    border-radius: 8px;
    color: white;
    padding: 8px;
}