.feature-block {
    background-color: #f3f3f3;
    display: flex;
    border-radius: 16px; 
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    box-shadow: 2px 2px 2px #bbbbbb;
    position: relative;
    transition: all .3s;
}

.feature-block:hover {
    box-shadow: 4px 4px 4px #aaaaaa;
    margin-left: 8px;
}

.feature-link {
    text-decoration: none;
}

.feature-title {
    margin-left: 8px;
    margin-right: 8px; 
    font-size: 20px;
    max-width: 260px;
    min-width: 160px;
    color: inherit;
    font-family: 'Cabin';
    font-size: 32px;
    margin: 20px;
    height: 60%;
    display: flex;
    align-items: center;
}

.feature-block-long {
    background-color: #68BB59;
    color: white;
    min-width: 690px;
}

.feature-block-short {
    background-color: #68BB59; 
    color: white;
}

.feature-block-mobile {
    flex-direction: column;
    min-width: 220px;
}

.feature-block-mobile-container {
    margin: 0 auto;
}

.business-marker-feature-left {
    margin: 20px; 
}

.business-marker-feature-right {
    margin: 20px;
}