.reset-password-container {
    border-radius: 16px;
}

.reset-password-title {
    color: white;
}

.reset-password-form {
    padding: 36px;
    max-width: 500px;
    margin: 0 auto;
    background-color: #69BB59;
    border-radius: 16px;
}