.update-info-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
}

.update-info-label {
    font-size: 20px;
    font-family: 'Cabin';
}

.update-info-input {
    border-radius: 4px;
}

.update-info-sub-label {
    font-size: 16px;
    font-family: 'Cabin';
    color: #444444;
}

.add-new-business-category-input {
    width: 250px;
}

.update-info-error {
    color: red;
    text-align: left;
}

.update-info-category-input {
    width: 250px;
}

.update-info-footer {
    display: flex;
    justify-content: right;
    margin-top: 8px;
}

.update-business-thumbnail-image {
    min-width: 120px;
    min-height: 120px;
    width: 120px;
    height: 120px;
    border-radius: 4px;
    border: 1px solid #E5E5E5; 
}

.update-business-cover-image {
    min-width: 160px;
    min-height: 90px;
    width: 160px;
    height: 90px;
    border-radius: 4px;
    border: 1px solid #E5E5E5; 
}