.home-container {
    /* background:var(--sl-green); */
    background-color: white;
    color: var(--sl-text);
    width: 100%;
    padding: 16px;
    padding-top: 0px;
    font-family: 'Cabin';
}

#home-logo {
    padding: 32px;
    padding-top: 38px; /* Offset logo shape */ 
    width: 100%;
    max-width: 600px;
    /* background-color: white;
    border: 6px solid var(--sl-green); */
    border-radius: 16px;
}

.home-paragraph {
    font-size: 24px;
    color: var(--sl-text);
    margin: 0 auto; 
    margin-bottom: 32px;
    max-width: 675px;
}

.home-emphasis {
    color: var(--sl-green);
}

.home-button {
    border-radius: 8px;
    padding: 16px;
    color: white;
    background-color: var(--sl-green);
    font-size: 48px; 
    transition: all .3s;
    max-width: 450px;
    width: 100%;
    display: block;
    margin: 0 auto; 
}

.home-button:hover {
    background-color: var(--sl-dark-green);
    color: white;
}

.home-description-container {
    margin-top: 64px;
}

.home-description-container:last-of-type {
    margin-bottom: 64px;
}

ol{
    counter-reset: item;
    justify-content: left;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 0 !important;
    max-width: 670px;
    margin: 0 auto;
}

li.home-list-item {
    display: block;
    font-size: 24px;
    margin-bottom: 32px;
}

li.home-list-item::before {
    display: inline-block;
    content: counter(item) ")";
    counter-increment: item;
    color: var(--sl-green);
    font-size: 32px;
    margin-right: 16px;
    font-weight: bold;
}

.home-text-body {
    font-size: 20px;
    max-width: 600px;
    margin: 0 auto; 
}

.home-icon-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.home-icon {
    height: 125px;
}

.home-icon.small {
    height: 75px;
}