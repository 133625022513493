.business-mobile-image-container {
    width: 100%;
    height: 180px;
    display: flex;
    justify-content: center;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    overflow: hidden;
    position: relative;
}

.business-mobile-cover-image {
    height:100%;
}

.business-mobile-contact-container {
    font-family: 'Cabin';
    display: flex;
    flex-flow: column;
}

.business-mobile-contact-name {
    text-align: left;
    font-size: 24px;
    color: #32A81D; 
    margin-bottom: 8px;
}

.business-mobile-contact-items {
    display: flex;
    flex-flow: row;
}

.business-mobile-section {
    border-bottom: 1px solid #EFEFEF;
    padding: 16px;
}

.business-mobile-contact-column {
    display: flex;
    flex-flow: column;
    width: 50%;
}

.business-mobile-contact-item {
    text-align: left;
    margin-bottom: 8px;


}

.business-mobile-contact-link {
    text-decoration: none;
    font-size: 16px;
    color: #777777; 
    display: flex;
    align-items: center;
}

.business-mobile-contact-link:hover {
    color: #4C4C4C;
}
.business-mobile-description {
    text-align: left;
    margin-bottom: 0px;
}

.business-mobile-about-us {
    text-align: left;
    width: 100%;
    font-weight: bold;
}