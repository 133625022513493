.business-marker-container {
    position: relative;
    height: 192px;
    width: 180px;

    min-height: 160px;
    min-width: 180px;

    border-radius: 8px; 
    border: 1px solid #E5E5E5;
    display: flex;
    background-color: #FFFFFF;
    flex-direction: column;
    overflow: hidden;
    margin: 12px; 
    justify-content: center;

    cursor: pointer; 
    transition: box-shadow .4s;
}

.business-marker-container:hover {
    transition: box-shadow .4s;
    box-shadow: 2px 2px 4px #999999;
}

.business-marker-image-container {
    width: 160px;
    height: 143px;
    overflow: hidden;
    border-radius: 4px;
    top: 10px; 
    left: 10px;
    position: absolute;
    border: none; 
    background-color: inherit;
}

.business-marker-image-container.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ACACAC;
}

.business-marker-image {
    object-fit: contain;
    height: 100%;
}

.business-marker-info {
    position: absolute;
    height: 20%;
    width: 100%; 
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4C4C4C;
    padding: 8px;
}

.business-marker-title {
    font-size: 18px;
    font-weight: bold;
    font-family: 'Cabin';
}

.business-marker-title.loading {
    background-color: #ACACAC;
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.business-marker-subtitle {
    font-size: 14px; 
}

.business-marker-deal {
    position: absolute;
    padding-right: 5px;
    padding-left: 5px;
    right: 20px;
    top: 20px;
    background-color: #FFF500;
    border-radius: 8px;
    z-index: 1;
    font-family: 'Cabin';
    font-size: 16px;
    font-weight: 500;
    color: inherit;
}

.business-marker-liked {
    color: #EC441E;
    background: white;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    position: absolute;
    left: 16px;
    top: 16px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E5E5E5;
}

.business-marker-liked-icon {
    width: .9em !important;
    height: .9em !important;
    margin-top: 4px;
    margin-bottom: auto;
}

.update-info-checkbox {
    margin-left: 16px;
    /* Double-sized Checkboxes */
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    transform: scale(2);
    cursor: pointer;
}

.update-info-label-container:first-of-type {
    margin-top: 0px;
}

.update-info-label-container {
    display: flex;
    flex-flow: column;
    margin-top: 16px;
}