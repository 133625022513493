.sign-in-body {
    width: 100%;
    height: 100%;
    display: flex;
}

.sign-in-form-container {
    width:30%;
    min-width: 300px;
    right: 0;
    background-color: #69BB59;
    display: flex;
    flex-direction: column;
    height: calc(100% - 70px); /* TODO add actual sizing */
}

.sign-in-info {
    height: 100%;
    width: 70%;
}

.sign-in-form {
    display: flex;
    flex-direction: column;
    padding: 15%;
    padding-top: 24px;
}

.sign-in-form-title {
    font-size: 30px;
    color: white;
    font-weight: bold;
}

.sign-in-form-subtitle {
    font-size: 16px;
    color: white;
}

.sign-in-form-label {
    color: white;
    text-align: left;
    padding-bottom: 8px;
    padding-top: 8px;
    font-weight: 600;
}

.sign-in-form-error {
    color: #BF2D2A;
    text-align: left;
    font-weight: 600;
}

.sign-in-form-input {
    height: 50px;
    border-radius: 8px;
    border: none; 
    background-color: #519D42;
    padding-left: 16px;
    color: #152612; 
    font-weight: 600;
}

.sign-in-form-wrapper {
    margin: auto 0; 
}

.sign-in-submit-container {
    padding-top: 16px;
    padding-bottom: 16px;
    width: 100%;
}

.sign-in-submit-separator {
    font-size: 20px;
    color: white;
    margin-top: 12px;
    margin-bottom: 12px;
}

.sign-in-hero-illustration {
    bottom: 0;
    position: absolute;
    left: 0; 
    z-index: -1;
    height: 60%;
}

.sign-in-info-text {
    top: 20%;
    left: 5%;
    position: absolute;
}

.sign-in-info-title {
    font-size: 72px;
    text-align: left;
}

.sign-in-info-subtitle {
    font-size: 24px;
    text-align: left;
}


/* Medium Screen Sizes */
@media screen and (max-width: 1100px) {
    .sign-in-info-title {
        font-size: 48px;
        text-align: left;
    }
    
    .sign-in-info-subtitle {
        font-size: 18px;
        text-align: left;
    }

    .sign-in-form {
        padding: 48px;
        padding-top: 14px; 
        width: 100%;
    }

    .sign-in-form-input {
        height: 30px;
    }


    .sign-in-submit-container {
        padding-top: 12px;
        padding-bottom: 12px;
    }

}

/* Sign In Form Mobile */

.sign-in-body-mobile {
    width: 100%;
    height: auto;
    background-color: #69BB59;
}

.sign-in-form-subtitle.mobile {
    font-size: 24px;
}

.sign-in-form-wrapper.mobile {
    padding-top: 16px;
}

.sign-in-form-input.mobile {
    height: 40px;
}

.sign-in-form-label.mobile {
    font-size: 20px;
}

.sign-in-terms-label {
    font-size: 16px !important;
}

.sign-in-submit-container {
    padding-top: 12px;
    padding-bottom: 12px;
}

.sign-in-submit-button-container.mobile {
    margin-top: 36px;
}

.sign-in-reset-password {
    color: white; 
    text-align: right;
    margin-top: 12px;
    margin-bottom: 16px;
}

.sign-in-reset-link {
    color: inherit;
    text-decoration: none;
}

.sign-in-checkbox {
    transform: scale(1.5);
    cursor: pointer;
}

.sign-in-checkbox-spacer {
    padding-right: 12px; 
}

.sign-in-terms-link {
    text-decoration: none;
}

/* Updated */

.sign-in-form.mobile {
    padding-top: 0px;
}

.sign-in-submit-button {
    width: 100%;
    max-width: 300px;
    height: 60px;
    font-size: 20px;
    background-color: #F2C94D; 
    color: #4D4B4C;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-top: 16px;
}

.sign-in-submit-button.mobile {
    margin-left: auto;
    margin-right: auto;
}

.sign-in-other-button {
    border: 2px solid white !important;
    color: white !important;
    width: 85%;
    background-color: inherit;
    height: 50px;
    margin: 0 auto;
}