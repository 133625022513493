
.user-profile-image {
    width: 128px;
    height: 128px;
    border-radius: 64px;
    min-width: 128px;
}

.manage-profile-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 16px;
}

.manage-profile-modal-body {
    display: flex;
    flex-direction: column;
}

.manage-profile-row-content {
    display: flex;
    align-items: baseline;
}

.manage-profile-image-button {
    background-color: white;
    color: #38862A;
    border: 1px solid #38862A;
    border-radius: 8px;
    margin-top: auto;
    margin-left: 16px;
    padding: 8px;
}

.manage-profile-footer {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: right;
}


.manage-profile-button-container {
    border-radius: 4px;
    font-size: 20px;
    overflow: hidden;
    margin: 8px;
    margin-bottom: none
}

.manage-profile-cancel-button {
    background-color: #FF4B69;
}

.manage-profile-accept-button {
    background-color: #38862A;
    color: white;
}
    
.manage-profile-form-input {
    border-radius: 4px;
}

.manage-profile-zip-code-input {
    width: 70px;
}

.manage-profile-form-error {
    color: red;
    text-align: left;
}