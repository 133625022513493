.payment-complete-page {
    font-family: 'Cabin';
    max-width: 400px; 
    padding: 32px;
    border: 1px solid #4C4C4C;
    border-radius: 16px;
}

.call-to-action-button-container {
    width: 200px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 0 auto;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    font-size: 24px;
    margin-top: 16px;
    margin-bottom: 16px;
    color: #4C4C4C;
    transition: .3s all;
    background-color: #38862A;
    color: white;
    overflow: hidden;
    cursor: pointer;
}

.call-to-action-button-container:hover {
    background-color: #F2C94D;
}

.success-icon {
    color: #38862A;
}

.fail-icon {
    color: #c23829;
}

.header-failed { 
    color: #a81f0f;
}