.nav-menu-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nav-bar {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    font-family: 'Cabin';
    font-weight: bolder;
}

.nav-bar-home-button {
    width: 180px; 
    margin-left: 48px;
    display: flex;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    vertical-align: middle;
}

.nav-bar-home-image {
    width: 100%;
}
.nav-button {
    background-color: inherit;
    border: 0; 
    padding: 0;
    color: inherit;
    border-radius: 4px;
}

.nav-bar-page-button {
    color: #4C4C4C;
    margin: 12px;
    font-size: 20px;
    font-weight: 550;
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 6px;
    padding-bottom: 6px;
    transition: all .3s;
}

.nav-bar-page-button:hover {
    box-shadow: 0px 2px 6px #DDDDDD;
}

.nav-menu-links-container {
    margin: 0 auto;
}

.nav-menu-action-container {
    margin-right: 48px;
    right: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nav-sign-up {
    color: #69BB59;
    box-shadow: 0px 2px 6px #DDDDDD;
}

.nav-sign-up:hover {
    box-shadow: 0px 2px 6px #BBBBBB;
}

.nav-bar-add-button-container {
    width: 40px;
    height: 40px;
    border-radius: 24px;
    border: none;
    padding: none; 
    margin: 0 auto;
    overflow: hidden;
    margin-right: 16px;
}

.nav-bar-add-button {
    width: 100%;
    height: 100%;
    border: none;
    padding: none;
    font-size: 48px;
    background-color: #F2A000; 
    color: white;
    font-family: 'Cabin';
    transition: all .3s;
    vertical-align: middle;
    line-height: 0px;
    text-align: center;
}

.nav-bar-add-image {
    width: 20px;
    height: 20px;
    margin: 0 auto;
}

.nav-bar-add-button:hover {
    background-color: #D88F01;
}

.nav-menu-user-image {
    width: 40px;
    height: 40px;
    border-radius: 24px;
    transition: all .3s;
}

.nav-menu-user-image:hover {
    box-shadow: 0px 2px 6px #BBBBBB;
}

/* Mobile */

.menu-icon {
    width: 100%;
    height: 100%;
    font-size: large;
}

.nav-bar-menu-button {
    margin-left: auto;
    margin-right: 40px; 
    height: 100%;
    width: 50px;
}

.nav-bar-mobile-items-container {
    width: 100%;
    height: 0%;
    display: flex;
    flex-flow: column;
    transition: all .3s;
}

.nav-bar-mobile-items-container .open {
    height: auto;
}

.nav-bar-mobile-menu-button {
    width: 100%;
    height: 50px;
    border: none;
    background-color: inherit;
    border-top: 2px solid #E5E5E5;
    font-size: 20px;
    font-family: 'Cabin';
    color: #4C4C4C;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-bar-mobile-menu-button:last-of-type {
    border-bottom: 2px solid #E5E5E5;
}

.nav-bar-mobile-menu-button:hover {
    background-color: #69BB59;
    color: white;
}

@media only screen and (max-width: 600px) {
    .nav-bar-home-button {
      margin-left: 16px;
    }

    .nav-bar-menu-button {
        margin-right: 16px;
    }
  }