.add-new-business-error {
    color: red;
    text-align: left;
}

.add-new-business-checkbox-row {
    display: flex;
    flex-direction: column;
}

.add-new-business-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.add-new-business-input {
    border-radius: 4px;
}

.add-new-business-label {
    font-size: 18px;
    font-family: 'Cabin';
}

.add-new-business-zip-code-input {
    width: 75px;
}

input.add-new-business-checkbox {
    transform: scale(1.5);
    margin-left: -10px;
}

.add-new-business-checkbox-label {
    display: block;
    text-indent: 15px;
}

.add-new-checkbox-spacer {
    padding-right: 4px; 
}