.add-business-marker-container {
    width: 280px;
    height: 110px;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #E5E5E5;
    padding: 10px;
    display: flex;
    align-items: center;
    margin-right: 16px;
    overflow: hidden;
}

.add-business-marker-image {
    min-width: 80px;
    max-width: 80px;
    height: 80px;
    border-radius: 4px;
    overflow: hidden;
    border: none;
}

.add-business-marker-name {
    font-size: 16px;
    font-family: 'Cabin';
    margin-bottom: 8px;
    font-weight: bold;
}

.add-business-marker-manage-button {
    color: #38862A;
    border: 1px solid #38862A;
    border-radius: 4px;
    justify-content: left;
    margin-left: 4px;
    display: flex;
    justify-content: center;
    vertical-align: center;
    padding: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.add-business-marker-manage-container {
    display: flex;
    flex-direction: row;
}

.add-business-marker-info {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
}

.add-business-marker-add-image {
    width: 35px;
    height: 35px;
    margin-top: auto;
}

.add-new-business-container {
    flex-direction: column;
}

.add-business-marker-add-text {
    margin-bottom: auto;
    font-size: 18px;
    color: #555555;
}

.add-new-business-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}