.add-new-cause-modal-preview {
    font-size: 16px;
    font-family: 'Cabin';
}

.add-new-cause-modal-preview-complete {
    font-size: 20px;
    font-family: 'Cabin';
}

.add-new-cause-modal-preview-terms {
    font-size: 12px;
}

.add-new-cause-label {
    margin-bottom: 8px;
}

.add-new-cause-pledge-card {
    border: 1px solid gray;
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 8px;
    margin-top: 8px;
}

.add-new-cause-submit-button {
    margin-left: auto;
    border-radius: 4px;
    overflow: hidden;
}
.add-new-cause-submit-button-container {
    float: right;
}

.add-new-cause-footer {
    display: flex;
    justify-content: right;
}

.add-new-cause-modal-title {
    font-size: 24px;
}