.local-business-top-container {
    display: flex;
    flex-direction: row;
    /* height: 650px; */
    margin-top: 32px;
    flex-wrap: wrap;
    justify-content: center;
    flex-basis: 300px;
    min-width: 380px;
}

.local-business-info-container {
    width: 800px;
    min-width: 380px;
    height: 100%;
    border-radius: 24px;
    background-color: white;
    margin-left: 12px;
    margin-right: 12px;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    margin-bottom: 24px;
}

.local-business-offer-container {
    min-width: 380px;
    width: 420px;
    background-color: white;
    overflow: hidden;
    border-radius: 16px;
    border: 1px solid #E5E5E5;
    padding: 24px;
    margin-bottom: 24px;
    margin-left: 12px;
    margin-right: 12px;
}

.local-business-cover-image {
    width: 100%;
    min-height: 320px;
    object-fit: cover;

}

.local-business-cover-image-container {
    width: 100%;
    height: 320px;
    min-height: 320px;
    overflow: hidden;
    position: relative;
    display: block;
    text-align: center;
}

.local-business-basic-info-container {
    width: 100%;
    height: auto;
    border: 1px solid #E5E5E5;
    flex: 1 1 auto;
    background-color: white;
    border-radius: inherit;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding: 24px;
    display: flex;
    flex-flow: column;
    justify-content: left;
}

.local-business-name {
    font-size: 24px;
    font-family: 'Cabin';
    font-weight: bold;
    text-align: left;
    margin-bottom: 16px;
}

.local-business-year-started {
    font-family: 'Cabin';
    font-weight: normal;
    font-size: 16px;
    margin-left: 8px;
}

.local-business-contact-info {
    display: flex;
    flex-flow: row wrap; 
    justify-content: center;

}

.local-business-contact-item {
    margin-left: 8px;
    margin-right: 8px;
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 8px;
    max-width: 250px;
    overflow: hidden;
}

.local-business-contact-item:first-child {
    margin-left: 0px;
}

.local-business-contact-icon {
    margin-right: 8px;
}

.local-business-long-description {
    text-align: left;
    font-size: 16px;
}

.local-business-offers {
    display: flex;
    flex-flow: column;
}

.local-business-offer-call {
    font-size: 18px;
    margin-bottom: 32px;
}

.local-link {
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
    text-decoration: none;
    color: #38862A;
}

.local-link:hover {
    color: #47A935;
}

.local-business-cause-call-to-action {
    margin-top: auto;
    font-size: 24px;
    padding: 16px; 
    font-family: 'Cabin';
}

.local-business-cause-tag-support-link {
    text-decoration: none;
}