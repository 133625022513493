.add-cause-tag-container {
    width: 60%;
    height: 80px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    border: 1px solid gray;
    margin: 0 auto;
    margin-top: 16px;
}

.add-cause-tag {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.add-cause-tag-image {
    width: 40px;
    height: 40px;
    margin-right: 16px;
}

.add-cause-tag-text {
    font-size: 20px;
    font-family: 'Cabin';
    text-align: center;
}