.loading-page {
    display: flex;
    width: 100%;
    height: 100%;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.loading-page-text {
    font-size: 32px;
    font-family: 'Cabin';
    margin-top: 32px;
}