.business-carousel {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.business-carousel-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding-top: 8px; 
    padding-bottom: 16px;
    border-bottom: 2px solid #eaeaea;
    position: relative;
    overflow: hidden;
}

.business-carousel-title {
    text-align: left;
    font-size: 24px;
    padding-bottom: 12px;
    font-family: 'Cabin';
    font-weight: bold;
}

.business-carousel-coming-soon {
    position: absolute;
    display: flex; 
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background: linear-gradient(to left, 
    rgba(255,255,255,60), rgba(255,255,255,0) 70%);
    text-align: right;
    align-items: center;
    justify-content: right;
    padding: 32px;
    font-size: 60px;
    font-family: 'Cabin';
    color: #4a4a4a; 
}