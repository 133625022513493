.cause-tag-mobile {
    width: 100%;
    height: 110px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    background-color: #68BB59;
    display: flex;
    font-family: 'Cabin';
    color: white;
}

.cause-tag-mobile-support-text {
    width: 70%;
    padding: 16px;
    padding-right: 8px;
    text-align: left;
}

.cause-tag-mobile-promise-goal {
    color: #2a6321;
    font-size: 16px;
}

.cause-tag-mobile-promise {
    font-size: 20px;
}

.cause-tag-mobile-support-button-container {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 16px;
}

.cause-tag-mobile-support-button {
    border: none;
    background-color: #FFDD66;
    color: #4C4C4C;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    font-size: 20px;
    padding: 8px;
}

.cause-tag-mobile-support-link {
    width: 100%;
    min-height: 40px;
}
