.form-tabs-header {
    display: flex;
}

.form-tabs-body {
    border: 1px solid #E5E5E5;
    padding: 8px;

    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.form-tab {
    padding: 16px;
    padding-bottom: 8px;
    border: 1px solid #E5E5E5;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    transition: all .3s;
    transition: height 0s;
    cursor: pointer;
    font-family: 'Cabin';
    font-size: 18px;
}  

.form-tab-selected {
    border-bottom: 4px solid #E5E5E5;
}