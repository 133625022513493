.user-profile-tag {
    position: relative;
    width: 30%;
    max-width: 400px;
    min-width: 275px;
    height: 175px;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #E5E5E5;
    align-items: center;
    padding: 16px;
    display: flex;
    overflow: hidden;
    margin: 16px;
}

.user-profile-tag-color {
    position: absolute;
    left: 0;
    width: 55%;
    height: 100%;
    border-top-right-radius: 120px;
    border-bottom-right-radius: 120px;
    
}

.user-profile-tag-number {
    position: absolute;
    font-size: 72px;
    font-family: 'Cabin';
    font-weight: 600;
    left: 7.5%;
    width: 40%;
}

.user-profile-tag-name {
    position: absolute;
    left: 60%;
    font-size: 28px;
    text-align: left;
    color: #555555; 
}