.favorite-icon {
    color: #EC441E;
    height: 100% !important;
    margin-right: 8px;
    margin-left: 8px;
}

.favorite-icon.liked {
    color: #EC441E;
}

.favorite-button-container {
    position: absolute;
    left: 20px;
    bottom: 20px;
    background-color: white;
    border-radius: 10px;

    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all .3s;
}

.favorite-button-container:hover {
    /* background-color: #C3DBBF; */
    box-shadow: 0px 0px 16px white;
}

.favorite-button-text {
    font-size: 18px;
    font-family: 'Cabin';
    margin-right: 8px;
}

